import React from 'react'
import styled from 'styled-components'
import { hsl } from 'polished'
import { Link } from 'gatsby'

const ModellBox = styled(Link)`
  width: calc(33.3% - 30px);
  cursor: pointer;
  overflow: hidden;
  margin: 15px;
  background-color: white;
  display: inline-block;
  @media (max-width: 1000px) {
    width: calc(50% - 30px);
    margin: 10px 15px;
  }
  @media (max-width: 678px) {
    width: calc(100% - 30px);
    margin: 10px 15px;
  }
  &:hover {
    img {
      margin-right: 0;
    }
  }
`

const Modellname = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weigth: 500;
  font-size: 24px;
  color: black;
  margin: 0;
`
const Modelltyp = styled.p`
  margin: 0;
`
const FlexBox = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 20px 20px;
  align-items: top;
  width: 100%;
  & div {
    display: inline-block;
  }
`

const Modellimage = styled.img`
  margin-top: 10px;
  margin-right: -10px;
  transition: all 0.3s ease-in-out;
`
const Logo = styled.img`
  background-color: black;
  padding: 5px;
  margin-bottom: 10px;
`

const TestDrive = styled(Link)`
  color: ${hsl(200, 0.99, 0.5)};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
`

const Details = styled(Link)`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
`

const Links = styled.div`
  padding: 0 20px 20px 20px;
  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  p {
    margin: 0;
  }
  & hr {
    margin-bottom: 20px;
    margin-top: 0;
    border-width: 0.5px;
  }
`

export const Modell = ({ name, logosrc, imagesrc, typ, link }) => (
  <ModellBox to={link}>
    <FlexBox>
      <div>
        <Logo src={logosrc} />
        <Modellname>{name}</Modellname>
        <Modelltyp>{typ}</Modelltyp>
      </div>
      <Modellimage src={imagesrc} />
    </FlexBox>
    <Links>
      <hr />
      <div>
        <Details to={link}>Details</Details>
        <TestDrive to="/probefahrt/">Probefahrt vereinbaren ></TestDrive>
      </div>
    </Links>
  </ModellBox>
)
