import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import { H1 } from '../components/Headlines'
import SEO from '../components/seo'
import Section from '../components/Section'
import { Modell } from '../components/Modell'

import Logo from '../images/icons/Mercedes-Benz-Logo.svg'

import AClassCompactLimousine from '../images/modells/a-class-compact-limousine.png'
// import AClassLimousine from '../images/modells/a-class-limousine.png'
// import BClass from '../images/modells/b-class.png'
// import CClassCabriolet from '../images/modells/c-class-cabriolet.png'
// import CClassCoupe from '../images/modells/c-class-coupe.png'
// import CClassLimousine from '../images/modells/c-class-limousine.png'
// import CClassTModell from '../images/modells/c-class-t-modell.png'
// import CLACoupe from '../images/modells/cla-coupe.png'
// import GLASuv from '../images/modells/gla-suv.png'
// import GLCCoupe from '../images/modells/glc-coupe.png'
// import GLCSuv from '../images/modells/glc-suv.png'

const Wrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  padding: 20px 0;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`S&G`, `Mercedes`, `Probefahrt`]} />

    <Section variant="grey" align="default">
      <H1>Modellübersicht</H1>

      <Wrapper>
        <Modell
          name="A Klasse"
          typ="Kompaktlimousine"
          imagesrc={AClassCompactLimousine}
          logosrc={Logo}
          link={'/a-klasse-kompaktlimousine/'}
        />

        {/* <Modell
          name="A Klasse"
          typ="Limousine"
          imagesrc={AClassLimousine}
          logosrc={Logo}
          link={'/a-klasse-limousine/'}
        />

        <Modell
          name="B Klasse"
          typ=""
          imagesrc={BClass}
          logosrc={Logo}
          link={'/b-klasse'}
        />

        <Modell
          name="C Klasse"
          typ="Limousine"
          imagesrc={CClassLimousine}
          logosrc={Logo}
          link={'/c-klasse-limousine/'}
        />

        <Modell
          name="C Klasse"
          typ="T-Modell"
          imagesrc={CClassTModell}
          logosrc={Logo}
          link={'/c-klasse-t-modell/'}
        /> 

        <Modell
          name="C Klasse"
          typ="Coupé"
          imagesrc={CClassCoupe}
          logosrc={Logo}
          link={'/c-klasse-coupe/'}
        />

        <Modell
          name="C Klasse"
          typ="Cabriolet"
          imagesrc={CClassCabriolet}
          logosrc={Logo}
          link={'/c-klasse-cabriolet/'}
        />

        <Modell
          name="CLA"
          typ="Coupé"
          imagesrc={CLACoupe}
          logosrc={Logo}
          link={'/cla-coupe/'}
        />

        <Modell
          name="GLA"
          typ="SUV"
          imagesrc={GLASuv}
          logosrc={Logo}
          link={'/gla-suv/'}
        />

        <Modell
          name="GLC"
          typ="Coupé"
          imagesrc={GLCCoupe}
          logosrc={Logo}
          link={'/glc-coupe/'}
        />
        
        <Modell
          name="GLC"
          typ="SUV"
          imagesrc={GLCSuv}
          logosrc={Logo}
          link={'/glc-suv/'}
        /> */}
      </Wrapper>
    </Section>
  </Layout>
)

export default IndexPage
